<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!--            <a-col :md="8" :sm="24">-->
            <!--              <a-form-item label="用户id,外键{zb_user.id}" prop="userId">-->
            <!--                <a-input v-model="queryParam.userId" placeholder="请输入用户id,外键{zb_user.id}" allow-clear />-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <!--            <a-col :md="8" :sm="24">-->
            <!--              <a-form-item label="后巷屋id,外键{zb_laneway_houses.id}" prop="lanewayHousesId">-->
            <!--                <a-input-->
            <!--                  v-model="queryParam.lanewayHousesId"-->
            <!--                  placeholder="请输入后巷屋id,外键{zb_laneway_houses.id}"-->
            <!--                  allow-clear-->
            <!--                />-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('服务管理.后巷屋订单管理.后巷屋标题')" prop="title">
                <a-input
                  v-model="queryParam.title"
                  :placeholder="$t('服务管理.后巷屋订单管理.请输入后巷屋标题')"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="后巷屋封面" prop="cover">-->
              <!--                  <a-input v-model="queryParam.cover" placeholder="请输入后巷屋封面" allow-clear />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="后巷屋定金" prop="deposit">-->
              <!--                  <a-input v-model="queryParam.deposit" placeholder="请输入后巷屋定金" allow-clear />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="后巷屋预估价格" prop="estimatedPrice">-->
              <!--                  <a-input v-model="queryParam.estimatedPrice" placeholder="请输入后巷屋预估价格" allow-clear />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="进度百分比" prop="progressPercentage">-->
              <!--                  <a-input v-model="queryParam.progressPercentage" placeholder="请输入进度百分比" allow-clear />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="定金已抵扣金额" prop="deductionMoney">-->
              <!--                  <a-input v-model="queryParam.deductionMoney" placeholder="请输入定金已抵扣金额" allow-clear />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="定金支付时间" prop="payTime">-->
              <!--                  <a-date-picker-->
              <!--                    style="width: 100%"-->
              <!--                    v-model="queryParam.payTime"-->
              <!--                    format="YYYY-MM-DD HH:mm:ss"-->
              <!--                    allow-clear-->
              <!--                  />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="定金支付过期时间" prop="expirationTime">-->
              <!--                  <a-date-picker-->
              <!--                    style="width: 100%"-->
              <!--                    v-model="queryParam.expirationTime"-->
              <!--                    format="YYYY-MM-DD HH:mm:ss"-->
              <!--                    allow-clear-->
              <!--                  />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="累计支付金额" prop="allMoney">-->
              <!--                  <a-input v-model="queryParam.allMoney" placeholder="请输入累计支付金额" allow-clear />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.重置') }}</a-button
                >
                <!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
                <!--                  {{ advanced ? '收起' : '展开' }}-->
                <!--                  <a-icon :type="advanced ? 'up' : 'down'" />-->
                <!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['order:lanewayHouses:add']">
          <a-icon type="plus" />新增
        </a-button> -->
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['order:lanewayHouses:edit']"
        >
          <a-icon type="edit" />{{ $t('通用.修改') }}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['order:lanewayHouses:remove']">
          <a-icon type="delete" />{{ $t('通用.删除') }}
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['order:lanewayHouses:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" @submitSucc="handleSubmitSucc" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
        <span slot="expirationTime" slot-scope="text, record">
          {{ parseTime(record.expirationTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['order:lanewayHouses:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['order:lanewayHouses:edit']">
            <a-icon type="edit" />{{ $t('服务管理.后巷屋订单管理.上传报告') }}
          </a>
          <a-divider type="vertical" v-hasPermi="['order:lanewayHouses:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['order:lanewayHouses:remove']">
            <a-icon type="delete" />{{ $t('通用.删除') }}
          </a>
          <a-divider type="vertical" v-hasPermi="['order:lanewayHouses:remove']" />
          <a @click="handleWatch(record)"> <a-icon type="search" />{{ $t('服务管理.后巷屋订单管理.查看服务') }} </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>

    <search-form ref="SearchFormRefs" :data="tableData" @baogaosuccess="Handlesuccess" />
  </page-header-wrapper>
</template>

<script>
import { pageLanewayHouses, listLanewayHouses, delLanewayHouses } from '@/api/order/lanewayHouses'
import { pageLanewayHousesServe, listLanewayHousesServe, delLanewayHousesServe } from '@/api/order/lanewayHousesServe'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import SearchForm from './modules/SearchForm.vue'
export default {
  name: 'LanewayHouses',
  components: {
    CreateForm,
    SearchForm,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        lanewayHousesId: null,
        cover: null,
        title: null,
        deposit: null,
        estimatedPrice: null,
        report: null,
        status: null,
        progressPercentage: null,
        deductionMoney: null,
        payStatus: null,
        payTime: null,
        expirationTime: null,
        allMoney: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        // {
        //   title: '主键',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center',
        // },
        // {
        //   title: '用户id,外键{zb_user.id}',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center',
        // },
        // {
        //   title: '后巷屋id,外键{zb_laneway_houses.id}',
        //   dataIndex: 'lanewayHousesId',
        //   ellipsis: true,
        //   align: 'center',
        // },
        // {
        //   title: '后巷屋封面',
        //   dataIndex: 'cover',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '后巷屋标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '后巷屋定金',
          dataIndex: 'deposit',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '后巷屋预估价格',
          dataIndex: 'estimatedPrice',
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '报告,外键{zb_file.id},多个使用逗号隔开',
        //   dataIndex: 'report',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '订单状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '待付款'
            } else if (text == 1) {
              return '已下单'
            } else if (text == 2) {
              return '制作方案'
            } else if (text == 3) {
              return '施工'
            } else if (text == 4) {
              return '已完成'
            } else {
              return '已关闭'
            }
          },
        },
        {
          title: '进度百分比',
          dataIndex: 'progressPercentage',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '定金已抵扣金额',
          dataIndex: 'deductionMoney',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '定金支付状态',
          dataIndex: 'payStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '待支付'
            } else {
              return '已支付'
            }
          },
        },
        {
          title: '定金支付时间',
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' },
          ellipsis: true,
          align: 'center',
          width: '160px',
        },
        // {
        //   title: '定金支付过期时间',
        //   dataIndex: 'expirationTime',
        //   scopedSlots: { customRender: 'expirationTime' },
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '累计支付金额',
          dataIndex: 'allMoney',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
          width: '160px',
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
      tableData: [],
      row: null,
    }
  },
  filters: {},
  created() {
    this.getList()
    this.updateColumns()
  },
  computed: {},
  watch: {
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  methods: {
    /** 查询后巷屋订单列表 */
    getList() {
      this.loading = true
      pageLanewayHouses(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        userId: undefined,
        lanewayHousesId: undefined,
        cover: undefined,
        title: undefined,
        deposit: undefined,
        estimatedPrice: undefined,
        report: undefined,
        status: undefined,
        progressPercentage: undefined,
        deductionMoney: undefined,
        payStatus: undefined,
        payTime: undefined,
        expirationTime: undefined,
        allMoney: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('服务管理.服务内容管理.确认删除所选中数据'),
        content: this.$t('服务管理.服务内容管理.当前选中编号为') + ids,
        okText: this.$t('通用.确认'),
        cancelText: this.$t('通用.取消'),
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delLanewayHouses(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'order/orderLanewayHouses/export',
            {
              ...that.queryParam,
            },
            `后巷屋订单_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
    handleWatch(row) {
      this.row = row
      pageLanewayHousesServe({
        orderLanewayHousesId: row.id,
        name: null,
        type: null,
        money: null,
        sort: null,
        status: null,
        payStatus: null,
        payTime: null,
        payScreenshot: null,
        payMoney: null,
        deductionMoney: null,
        fileIds: null,
        pageNum: 1,
        pageSize: 10,
      }).then((response) => {
        this.tableData = response.data
        this.$refs.SearchFormRefs.showModal(row.id)
      })
    },
    handleSubmitSucc() {
      this.getList()
    },
    Handlesuccess() {
      this.handleWatch(this.row)
    },
    updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
          {
            title: 'title',
            dataIndex: 'title',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'deposit',
            dataIndex: 'deposit',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'estimatedPrice',
            dataIndex: 'estimatedPrice',
            ellipsis: true,
            align: 'center',
          },

          {
            title: 'status',
            dataIndex: 'status',
            ellipsis: true,
            align: 'center',
            customRender: function (text) {
              if (text == 0) {
                return '待付款'
              } else if (text == 1) {
                return '已下单'
              } else if (text == 2) {
                return '制作方案'
              } else if (text == 3) {
                return '施工'
              } else if (text == 4) {
                return '已完成'
              } else {
                return '已关闭'
              }
            },
          },
          {
            title: 'ProgressPercentage',
            dataIndex: 'progressPercentage',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'deductionMoney',
            dataIndex: 'deductionMoney',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'PayStatus',
            dataIndex: 'payStatus',
            ellipsis: true,
            align: 'center',
            customRender: function (text) {
              if (text == 0) {
                return '待支付'
              } else {
                return '已支付'
              }
            },
          },
          {
            title: 'PayTime',
            dataIndex: 'payTime',
            scopedSlots: { customRender: 'payTime' },
            ellipsis: true,
            align: 'center',
            width: '160px',
          },

          {
            title: 'allMoney',
            dataIndex: 'allMoney',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'CreateTime',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
            width: '160px',
          },

          {
            title: 'Operation',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
        'zh-CN': [
          {
            title: '后巷屋标题',
            dataIndex: 'title',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '后巷屋定金',
            dataIndex: 'deposit',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '后巷屋预估价格',
            dataIndex: 'estimatedPrice',
            ellipsis: true,
            align: 'center',
          },

          {
            title: '订单状态',
            dataIndex: 'status',
            ellipsis: true,
            align: 'center',
            customRender: function (text) {
              if (text == 0) {
                return '待付款'
              } else if (text == 1) {
                return '已下单'
              } else if (text == 2) {
                return '制作方案'
              } else if (text == 3) {
                return '施工'
              } else if (text == 4) {
                return '已完成'
              } else {
                return '已关闭'
              }
            },
          },
          {
            title: '进度百分比',
            dataIndex: 'progressPercentage',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '定金已抵扣金额',
            dataIndex: 'deductionMoney',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '定金支付状态',
            dataIndex: 'payStatus',
            ellipsis: true,
            align: 'center',
            customRender: function (text) {
              if (text == 0) {
                return '待支付'
              } else {
                return '已支付'
              }
            },
          },
          {
            title: '定金支付时间',
            dataIndex: 'payTime',
            scopedSlots: { customRender: 'payTime' },
            ellipsis: true,
            align: 'center',
            width: '160px',
          },

          {
            title: '累计支付金额',
            dataIndex: 'allMoney',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '插入时间',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
            width: '160px',
          },

          {
            title: '操作',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>
